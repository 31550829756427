/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import * as Sentry from "@sentry/react";

function loadSentry(){
    Sentry.init({
        dsn: "https://8740cae74e01bf8fcfa6bb8c96284ac9@o4505833018949632.ingest.us.sentry.io/4505884211216384",
        environment: "Production",
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration({maskAllText: false, blockAllMedia: false})],
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
}

export const onClientEntry = () => {
    loadSentry();
  }
